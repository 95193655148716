import * as React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import Seo from '../components/Seo'

const SubscriptionsContainer = styled.div`
  margin-top: 200px;
  margin-bottom: 100px;
  background-color: #fefff9;
`

function Subscriptions({ location }) {
  const [userId, setUserId] = React.useState(null)

  React.useEffect(() => {
    const query = new URLSearchParams(location.search)
    const userId = query.get('userId')
    // If userid is present, and its numeric, show the widget. Else, redirect to 404
    if (userId && !isNaN(userId)) {
      setUserId(userId)
    } else {
      navigate('/404')
    }
  }, [])

  return (
    !!userId && (
      <SubscriptionsContainer>
        <Seo title="Subscriptions" />
        <stripe-pricing-table
          pricing-table-id="prctbl_1M1dEiLjYjv87FqKN5EeB7Pl"
          publishable-key="pk_live_51Lu72oLjYjv87FqKBpeiCvXuHnIIiLDxOw6dqtQ00OC4mfjHy8sK3VfqtxCWuz7H2jKREjfsqI6xcqqRPfqbbrCO00EfqGdVGj"
          client-reference-id={`${userId}`}
        ></stripe-pricing-table>
      </SubscriptionsContainer>
    )
  )
}

export default Subscriptions
